
import './App.css';
import fluxlogo from './fluxlogo.jpg'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={fluxlogo} className="App-logo" alt="" />
        <p className='CS'>
          COMING SOON
        </p>
        
      </header>
    </div>
  );
}

export default App;
